import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div style={{
      backgroundImage: "url('/static/ve7qnx_board.jpg')",
      height: "500px",
      width: "100%",
      backgroundSize: "cover"
    }}></div>
    <h1>About VE7QNZ</h1>
    <p>VE7QNX is the callsign that I use for unattended amateur radio projects.</p>

    <h2>Repeater</h2>
    <p>
      443.375 +5.000 MHz T100.0<br />
      IRLP Node 1337
    </p>

    <p>The repeater is located in Gibsons, BC and serves the Gibsons area.  I have not tested it with anyone on Vancouver Island yet.</p>

    <p>The repeater is a Harris Alpha 2000.  Dave Cameron, VE7LTD (<a href="http://irlp.net/">IRLP</a>), put the repeater together for me.  It has a built in 12V to 5V converter for the Raspberry Pi that controls the repeater and provides access to the IRLP network.</p>

    <p>The duplexer is a Sinclair Res-Lok duplexer.</p>

    <p>The repeater is currently running on a power supply as my solar charge controller is acting up.</p>

    <h2>Future Projects</h2>
    <p>I am slowly working on getting a packet server and APRS Igate up and running.</p>
    
  </Layout>
)

export default IndexPage
